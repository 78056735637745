import React from "react";
import { Route, Switch } from "react-router-dom";

import { PrivateRoute } from './components/PrivateRoute'

import Home from "./containers/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Products from "./containers/Products";
import Merchants from "./containers/Merchants";
import Tours from "./containers/Tours";
import EditProductStandalone from "./containers/EditProductStandalone";
import EditMerchantStandalone from "./containers/EditMerchantStandalone";
import ImportProducts from "./containers/ImportProducts";
import ImportReview from "./containers/ImportReview";
import ReviewProducts from "./containers/ReviewProducts";
import ReviewProductDiff from "./containers/ReviewProductDiff";
import Users from "./containers/Users";
import Admin from "./containers/Admin";
import CategoryManagement from "./containers/CategoryManagement";
import Keywords from "./containers/Keywords";
import ProductConversion from "./containers/ProductConversion";
import MediaManager from "./containers/MediaManager";
import DataIntegrity from "./containers/DataIntegrity";

export default () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/login" exact component={Login} />
    <PrivateRoute path="/reset_password" exact component={ResetPassword} />
    <PrivateRoute path="/products" exact component={Products} roles={["Editor", "Reviewer"]}/>
    <PrivateRoute path="/import_review" exact component={ImportReview} roles={["Editor", "Reviewer"]}/>
    <PrivateRoute path="/import_products" exact component={ImportProducts} roles={["Editor"]}/>
    <PrivateRoute path="/review_products" exact component={ReviewProducts} roles={["Reviewer"]}/>
    <PrivateRoute path="/EditProduct/:product_id" component={EditProductStandalone} roles={["Editor"]}/>
    <PrivateRoute path="/EditMerchant/:merchant_id" component={EditMerchantStandalone} roles={["Editor"]}/>
    <PrivateRoute path="/ReviewEdit/:edit_stage_id/:product_id" component={ReviewProductDiff} roles={["Reviewer"]}/>
    <PrivateRoute path="/ReviewEdit/:edit_stage_id" component={ReviewProductDiff} roles={["Reviewer"]}/>
    <PrivateRoute path="/merchants" exact component={Merchants} roles={["Editor", "Reviewer"]}/>
    <PrivateRoute path="/tours" exact component={Tours} roles={["Editor", "Reviewer"]}/>
    <PrivateRoute path="/Users" exact component={Users} roles={["Admin"]}/>
    <PrivateRoute path="/Admin" exact component={Admin} roles={["Admin"]}/>
    <PrivateRoute path="/ProductConversion" exact component={ProductConversion} roles={["Admin"]}/>
    <PrivateRoute path="/CategoryManagement" exact component={CategoryManagement} roles={["Admin"]}/>
    <PrivateRoute path="/KeywordManagement" exact component={Keywords} roles={["Admin"]}/>
    <PrivateRoute path="/MediaManager" exact component={MediaManager} roles={["Admin"]}/>
    <PrivateRoute path="/DataIntegrity" exact component={DataIntegrity} roles={["Admin", "Editor", "Reviewer"]}/>
  </Switch>;