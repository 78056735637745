import React, {useEffect, useState, useContext} from 'react';
import _ from "lodash";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

import { useSnackbar } from 'notistack';

import { Link } from "react-router-dom";

import { getURL } from '../_helpers/url-builder';
import LoadingSpinner from '../components/LoadingSpinner';

import EditMerchant from "../components/EditMerchant";

import { useAPI } from '../_helpers/auth-request';
import { LanguageContext } from '../contexts/language-context';

// const EXPORT_MERCHANT_URL = getURL('export-merchants');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    marginLeft: '100px',
  },
}));

export default function Tours() {
  const classes = useStyles();
  const { callApiAsync } = useAPI();

  const initialEditMerchantDialogState = { 
    open: false, 
    editMode: false, 
    newMerchant: false, 
    merchantID: null,
    editStageID: null
  }
  
  const [merchantData, setData] = useState({merchantList: [], isFetching: false});
  const [editMerchantDialog, setEditMerchantDialog] = useState(initialEditMerchantDialogState);
  const { language } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async() => {
      try{
        setData({merchantList: [], isFetching: true});
        const response = await callApiAsync({
          method: 'get',
          url: getURL('get-all-merchants')
        })
        setData({merchantList: response.data, isFetching: false});
      } catch(e) {
        console.log(e);
        setData({merchantList: [], isFetching: false});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  function handleEditMerchantDialogOpen(newMerchant) {
    setEditMerchantDialog({ open: true, editMode: false, newMerchant: newMerchant, merchantID: null, editStageID: null});
  }

  async function deleteMerchant(merchantData) {
    let merchant_id = merchantData.merchant_id;
    let pData = _.cloneDeep(merchantData);
    pData.mark_remove = true;
    console.log(`Getting ready to remove Merchant ${merchant_id}`);
    const removeMerchantURL = getURL('remove-merchant', merchant_id);
    try {
      const response = await callApiAsync({
          method: 'post',
          url: removeMerchantURL,
          data: {
            merchant_data: pData
          }
      });
      if(response.status === 200) {
          enqueueSnackbar('Merchant has been marked for removal',  { variant: 'success' })
          return true;
          // refetchCategoryData();
      } else {
        enqueueSnackbar('Encountered an issue while removing the merchant',  { variant: 'error' })
        return false;
    }
    } catch(e) {
      enqueueSnackbar('Encountered an issue while removing the merchant',  { variant: 'error' })
      return false;
    }
    
  }

  // async function toggleMerchantVisibility(merchantData) {
  //   let merchant_id = merchantData.merchant_id;
  //   const updateMerchantVisibilityURL = getURL('update-merchant-visibility', merchant_id);
  //   let data = {
  //     is_visible: !merchantData.is_visible
  //   }
  //   try {
  //     const response = await callApiAsync({
  //         method: 'post',
  //         url: updateMerchantVisibilityURL,
  //         data
  //     });
  //     if(response.status === 200) {
  //         enqueueSnackbar(`Merchant is now marked as ${data.is_visible ? 'Visibile' : 'NOT Visible'}`,  { variant: 'success' })
  //         return true;
  //     } else {
  //       enqueueSnackbar('Encountered an issue while updating the merchant',  { variant: 'error' })
  //       return false;
  //   }
  //   } catch(e) {
  //     enqueueSnackbar('Encountered an issue while updating the merchant',  { variant: 'error' })
  //     return false;
  //   }
  // }

  async function cancelDeleteMerchant(merchantData) {
    let merchant_id = merchantData.merchant_id;
    let pData = _.cloneDeep(merchantData);
    pData.mark_remove = false;
    
    const cancelRemoveMerchantURL = getURL('undo-remove-merchant', merchant_id);
    try {
      const response = await callApiAsync({
          method: 'post',
          url: cancelRemoveMerchantURL,
          data: {
            merchant_data: pData
          }
      });
      if(response.status === 200) {
          enqueueSnackbar('Merchant is no longer marked for removal',  { variant: 'success' })
          return true;
      } else {
        enqueueSnackbar('Encountered an issue while updating the merchant',  { variant: 'error' })
        return false;
    }
    } catch(e) {
      enqueueSnackbar('Encountered an issue while updating the merchant',  { variant: 'error' })
      return false;
    }
    
  }

  // async function exportMerchants() {
  //   window.open(EXPORT_MERCHANT_URL, "_blank");
  // }

  function handleEditMerchantDialogClose() {
    setEditMerchantDialog(initialEditMerchantDialogState);
  }

  function resolveName(nameObj) {
    if(nameObj[language]) {
        return nameObj[language];
    } else {
        let defaultKey = Object.keys(nameObj)[0];
        return nameObj[defaultKey];
    }
  }

  // async function handleVisibilityClick(rowData) {
  //   let result = await toggleMerchantVisibility(rowData);
  //   if(result) {
  //     const updateList = _.map(merchantData.merchantList, p => p.merchant_id !== rowData.merchant_id ? p : {...p, is_visible: !rowData.is_visible});
  //     setData({...merchantData, merchantList: updateList})
  //   }
  // }

  async function handleDeleteClick(rowData) {
    let result = false;
    if(rowData.mark_remove) {
        result = await cancelDeleteMerchant(rowData);
    } else {
        result = await deleteMerchant(rowData);
    }
    // debugger;
    if(result) {
        const updateList = _.map(merchantData.merchantList, p => p.merchant_id !== rowData.merchant_id ? p : {...p, mark_remove: !rowData.mark_remove});
        setData({...merchantData, merchantList: updateList})
    }
  }

  return (
      <Container component="main">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={(event) => handleEditMerchantDialogOpen(true)}
        >
          Add Merchant
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<DownloadIcon />}
          onClick={(event) => exportMerchants()}
        >
          Export Merchants
        </Button> */}
        <MaterialTable
          maxWidth="xs"
          columns={[
            // {
            //   field: 'is_visible',
            //   type: 'boolean',
            //   title: 'Is Visible?',
            //   render: rowData => {
            //       if(rowData.is_visible) {
            //           return (
            //             <IconButton aria-label="add" className={classes.margin} onClick={() => handleVisibilityClick(rowData)}>
            //                 <VisibilityIcon fontSize="inherit" />
            //             </IconButton>
            //           )
            //       } else {
            //         return (
            //           <IconButton aria-label="add" className={classes.margin} onClick={() => handleVisibilityClick(rowData)}>
            //               <VisibilityOffIcon fontSize="inherit" />
            //           </IconButton>
            //         )
            //       }
            //   }
            // },
            {
              field: 'merchant_id', 
              type: 'string', 
              title: 'Merchant ID'
            },
            {
              field: `lang.merchant_name[${language}]`, 
              type: 'string', 
              title: 'Merchant Name',
              render: rowData => <span>{resolveName(rowData.lang.merchant_name)}</span>
            },
            {
              field: 'actions',
              title: 'Actions',
              filtering: false,
              render: rowData => {
                return (
                  <span>
                      <Link to={`/EditMerchant/${rowData.merchant_id}`}>
                          <IconButton>
                              <EditIcon />
                          </IconButton>
                      </Link>
                      <IconButton 
                          className={classes.margin}
                          onClick={(event) => {handleDeleteClick(rowData)}}>
                          {rowData.mark_remove ? <RestoreFromTrashIcon /> : <DeleteIcon />}
                      </IconButton>
                  </span>
                )
              }
            }
          ]}
          components={{
            OverlayLoading: LoadingSpinner
          }}
          options={{
            search: true,
            toolbar: false,
            paging: true,
            filtering: true,
            actionsColumnIndex: -1
          }}
          isLoading={merchantData.isFetching}
          data={merchantData.merchantList}
        />
        <EditMerchant 
          editMode={editMerchantDialog.editMode}
          merchantID={editMerchantDialog.merchantID}
          newMerchant={editMerchantDialog.newMerchant}
          open={editMerchantDialog.open}
          onClose={handleEditMerchantDialogClose}
          onSave={handleEditMerchantDialogClose}
        />
      </Container>
  );
}